import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const TemplateCard = ({ template, onClick, color }) => {
  const imgSrcDefault = `https://static.edit.site/files/13/${template.src.name}`;
  const imgSrcSm = `https://static.edit.site/files/12/${template.src.name}`;
  const imgSrcMd = `https://static.edit.site/files/12/${template.src.name}`;
  const imgSrcLg = `https://static.edit.site/files/13/${template.src.name}`;

  return (
    <div className="relative group overflow-hidden cursor-pointer" onClick={onClick} >
      <div className="w-full h-auto sm:h-38 md:h-38 lg:h-56 xl:h-80">
        <LazyLoadImage
          wrapperClassName="w-full"
          className="w-full h-full object-cover"
          alt={template.title}
          effect="blur"
          src={imgSrcDefault}
          srcSet={`
            ${imgSrcSm} 600w,
            ${imgSrcMd} 900w,
            ${imgSrcLg} 1200w
          `}
          sizes="(max-width: 600px) 100vw, (max-width: 900px) 50vw, 33vw"
          threshold={500}
        />
      </div>
      <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <button
          className="bg-purple-500 text-white py-2 px-4 rounded-lg uppercase"
          style={{ backgroundColor: color, color: '#fff' }}
        >Preview</button>
      </div>
    </div>
  );
};

export default TemplateCard;
