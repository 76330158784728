import React from 'react';

const PreviewPopup = ({ template, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"  onClick={onClose}>
      <div className="relative bg-white p-6 rounded-lg shadow-lg w-11/12 h-5/6 overflow-y-auto">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-black"
        >
          ✖
        </button>
        <iframe src={`https://${template.versions[0].link}`} title={template.title}
                className="w-full h-full object-cover rounded-md"/>
      </div>
    </div>
  );
};

export default PreviewPopup;