import React from 'react';

const Navigation = ({ selectedCategory, onCategoryChange, color }) => {
  const categories = [
    'All',
    'Business',
    'Store',
    'Blog',
    'Services',
    'Personal',
    'Health',
    'Beauty',
    'Restaurant',
    'Portfolio',
  ];

  return (
    <nav className="flex justify-center space-x-6 py-4 bg-white shadow-md">
      {categories.map((category) => (
        <button
          key={category}
          className="font-medium"
          style={{
            color: selectedCategory === category ? color : 'gray',
            textDecoration: 'none',
          }}
          onClick={() => onCategoryChange(category)}
        >
          {category.toUpperCase()}
        </button>
      ))}
    </nav>
  );
};

export default Navigation;