import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';

import Navigation from './components/Navigation';
import TemplateCard from './components/TemplateCard';
import PreviewPopup from './components/PreviewPopup';

import './App.css';

const API_URL = process.env.REACT_APP_API_URL;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const App = () => {
  const query = useQuery();
  const [color, setColor] = useState('#000');
  const [reseller, setReseller] = useState(null);

  const [templates, setTemplates] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const queryReseller = query.get('reseller');
    const queryColor = query.get('color');
    if(queryReseller) {
      setReseller(queryReseller)
    }
    if (queryColor) {
      setColor(queryColor);
    }
  }, [query]);

  useEffect(() => {
    const fetchTemplates = async () => {
      let templatesEndpoint = `${API_URL}/api/templates`;
      if (reseller) {
        templatesEndpoint += `?resellerId=${reseller}`;
      }
      try {
        const response = await fetch(templatesEndpoint);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setTemplates(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, [reseller]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <ReactLoading type="spin" color={color} height={80} width={80} />
      </div>
    );
  }
  if (error) return <p>Error: {error}</p>;

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleOpenPopup = (template) => {
    setSelectedTemplate(template);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const filteredTemplates =
    selectedCategory === 'All'
      ? templates
      : templates.filter((template) => template.categories.includes(selectedCategory));

  return (
    <div className="min-h-screen">
      <Navigation
        selectedCategory={selectedCategory}
        onCategoryChange={handleCategoryChange}
        color={color}
      />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
          {filteredTemplates.map((template) => (
            <TemplateCard
              key={template.templateIndex}
              template={template}
              color={color}
              onClick={() => handleOpenPopup(template)}
            />
          ))}
        </div>
      </div>
      {showPopup && selectedTemplate !== '' && <PreviewPopup template={selectedTemplate} onClose={handleClosePopup}/>}
    </div>
  );
};

export default App;
